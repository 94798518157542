import React from "react";
import { includes } from "lodash";
import classNames from "classnames";

const KeyboardKey = ({
  text,
  onPress,
  shouldOmitWidthClass = false,
  isGuessed,
}) => {
  return (
    <button
      onClick={onPress}
      className={classNames(
        "text-gray-800 font-bold rounded px-2 sm:px-4 py-1 sm:py-2 h-8 sm:h-12",
        {
          "w-8 sm:w-12": !shouldOmitWidthClass,
          "bg-gray-300": !isGuessed,
          "bg-gray-400": isGuessed,
        }
      )}
    >
      {text}
    </button>
  );
};

const Keyboard = ({ onPress, guessedLetters, containerRef, isUpsideDown }) => {
  return (
    <div
      className={classNames(
        "fixed bottom-0 left-0 right-0 bg-white py-4 border-gray-200 flex flex-col items-center justify-center gap-y-1 sm:gap-y-2",
        { "border-b rotate-180": isUpsideDown, "border-t": !isUpsideDown }
      )}
      ref={containerRef}
    >
      <div className="flex justify-center items-center gap-x-1">
        <KeyboardKey
          text="Q"
          onPress={() => onPress("Q")}
          isGuessed={includes(guessedLetters, "Q")}
        />
        <KeyboardKey
          text="W"
          onPress={() => onPress("W")}
          isGuessed={includes(guessedLetters, "W")}
        />
        <KeyboardKey
          text="E"
          onPress={() => onPress("E")}
          isGuessed={includes(guessedLetters, "E")}
        />
        <KeyboardKey
          text="R"
          onPress={() => onPress("R")}
          isGuessed={includes(guessedLetters, "R")}
        />
        <KeyboardKey
          text="T"
          onPress={() => onPress("T")}
          isGuessed={includes(guessedLetters, "T")}
        />
        <KeyboardKey
          text="Y"
          onPress={() => onPress("Y")}
          isGuessed={includes(guessedLetters, "Y")}
        />
        <KeyboardKey
          text="U"
          onPress={() => onPress("U")}
          isGuessed={includes(guessedLetters, "U")}
        />
        <KeyboardKey
          text="I"
          onPress={() => onPress("I")}
          isGuessed={includes(guessedLetters, "I")}
        />
        <KeyboardKey
          text="O"
          onPress={() => onPress("O")}
          isGuessed={includes(guessedLetters, "O")}
        />
        <KeyboardKey
          text="P"
          onPress={() => onPress("P")}
          isGuessed={includes(guessedLetters, "P")}
        />
      </div>
      <div className="flex justify-center items-center gap-x-1">
        <KeyboardKey
          text="A"
          onPress={() => onPress("A")}
          isGuessed={includes(guessedLetters, "A")}
        />
        <KeyboardKey
          text="S"
          onPress={() => onPress("S")}
          isGuessed={includes(guessedLetters, "S")}
        />
        <KeyboardKey
          text="D"
          onPress={() => onPress("D")}
          isGuessed={includes(guessedLetters, "D")}
        />
        <KeyboardKey
          text="F"
          onPress={() => onPress("F")}
          isGuessed={includes(guessedLetters, "F")}
        />
        <KeyboardKey
          text="G"
          onPress={() => onPress("G")}
          isGuessed={includes(guessedLetters, "G")}
        />
        <KeyboardKey
          text="H"
          onPress={() => onPress("H")}
          isGuessed={includes(guessedLetters, "H")}
        />
        <KeyboardKey
          text="J"
          onPress={() => onPress("J")}
          isGuessed={includes(guessedLetters, "J")}
        />
        <KeyboardKey
          text="K"
          onPress={() => onPress("K")}
          isGuessed={includes(guessedLetters, "K")}
        />
        <KeyboardKey
          text="L"
          onPress={() => onPress("L")}
          isGuessed={includes(guessedLetters, "L")}
        />
      </div>
      <div className="flex justify-center items-center gap-x-1">
        <KeyboardKey
          text="Enter"
          onPress={() => onPress("Enter")}
          shouldOmitWidthClass
          isGuessed={false}
        />
        <KeyboardKey
          text="Z"
          onPress={() => onPress("Z")}
          isGuessed={includes(guessedLetters, "Z")}
        />
        <KeyboardKey
          text="X"
          onPress={() => onPress("X")}
          isGuessed={includes(guessedLetters, "X")}
        />
        <KeyboardKey
          text="C"
          onPress={() => onPress("C")}
          isGuessed={includes(guessedLetters, "C")}
        />
        <KeyboardKey
          text="V"
          onPress={() => onPress("V")}
          isGuessed={includes(guessedLetters, "V")}
        />
        <KeyboardKey
          text="B"
          onPress={() => onPress("B")}
          isGuessed={includes(guessedLetters, "B")}
        />
        <KeyboardKey
          text="N"
          onPress={() => onPress("N")}
          isGuessed={includes(guessedLetters, "N")}
        />
        <KeyboardKey
          text="M"
          onPress={() => onPress("M")}
          isGuessed={includes(guessedLetters, "M")}
        />
        <KeyboardKey
          text="⌫"
          onPress={() => onPress("Backspace")}
          isGuessed={false}
        />
      </div>
    </div>
  );
};
export default Keyboard;
