import { map, filter, times } from "lodash";

const gradeWord = (solutionWord, testWord) => {
  return map(
    times(5, (index) => {
      const testLetter = testWord[index];
      if (testLetter === solutionWord[index]) {
        return "green";
      }
      if (!solutionWord.includes(testLetter)) {
        return "gray";
      }

      const numOfThisLetterInSolution = filter(
        solutionWord.split(""),
        (letter) => letter === testLetter
      ).length;
      const numGreenOfThisLetter = filter(
        times(
          5,
          (subIndex) =>
            solutionWord[subIndex] === testWord[subIndex] &&
            solutionWord[subIndex] === testLetter
        )
      ).length;
      const numOfThisLetterPrior = filter(
        times(index),
        (subIndex) => testWord[subIndex] === testLetter
      ).length;

      if (
        numGreenOfThisLetter + numOfThisLetterPrior >=
        numOfThisLetterInSolution
      ) {
        return "gray";
      } else {
        return "yellow";
      }
    })
  );
};
export default gradeWord;

// const testGradeWord = () => {
//   const testResults = [];

//   const runTestCase = (solutionWord, testWord, expectedOutputString) => {
//     const expectedOutput = map(expectedOutputString.split(""), (char) =>
//       char === "g" ? "green" : char === "y" ? "yellow" : "gray"
//     );
//     const output = gradeWord(solutionWord, testWord);
//     const isPass = every(
//       map(
//         zip(output, expectedOutput),
//         ([actual, expected]) => actual === expected
//       )
//     );

//     testResults.push({ solutionWord, testWord, isPass, expectedOutputString });
//   };

//   runTestCase("HAPPY", "IRATE", "xxyxx");
//   runTestCase("ABCDE", "ABZEY", "ggxyx");
//   runTestCase("ABCDE", "ZZZZA", "xxxxy");
//   runTestCase("ABCDE", "ZZZAZ", "xxxyx");
//   runTestCase("ABCDE", "ZZAZZ", "xxyxx");
//   runTestCase("ABCDE", "ZAZZZ", "xyxxx");
//   runTestCase("ABCDE", "AZZZZ", "gxxxx");
//   runTestCase("ABCDE", "AZCZZ", "gxgxx");
//   runTestCase("ABCDE", "AZCZD", "gxgxy");
//   runTestCase("ABCDE", "AZZZA", "gxxxx");
//   runTestCase("SHILL", "LOLLY", "yxxgx");

//   console.table(testResults);
//   console.log(
//     `${filter(testResults, "isPass").length} pass, ${
//       reject(testResults, "isPass").length
//     } fail`
//   );
// };
// testGradeWord();
