import React from "react";
import { times } from "lodash";

import WordleRow from "./WordleRow";
import { ROW_GAP } from "../size-constants";

const WordleBoard = ({
  boardNumber,
  solutionWord,
  numRows,
  currentGuess,
  previousGuesses,
}) => {
  const solutionIndex = previousGuesses.indexOf(solutionWord);

  return (
    <div>
      <h3 className="font-bold text-gray-800">Board {boardNumber}</h3>

      <div
        className="flex flex-col items-center justify-start"
        style={{ rowGap: ROW_GAP }}
      >
        {times(numRows, (index) => {
          const isPastSolution =
            solutionIndex === -1 ? false : index > solutionIndex;
          const isCurrentGuess = index === previousGuesses.length;
          const isPreviousGuess = index < previousGuesses.length;

          return (
            <WordleRow
              key={index}
              solutionWord={solutionWord}
              word={
                isPastSolution
                  ? ""
                  : isCurrentGuess
                  ? currentGuess
                  : isPreviousGuess
                  ? previousGuesses[index]
                  : ""
              }
              state={
                isPastSolution ? "blocked" : isPreviousGuess ? "graded" : ""
              }
            />
          );
        })}
      </div>
    </div>
  );
};
export default React.memo(WordleBoard);
