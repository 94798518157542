const latinizeNameForNumber = (number) => {
  const absoluteNumber = Math.abs(number);
  const negativePrefix = number < 0 ? "nega" : "";

  if (absoluteNumber <= 10) {
    switch (absoluteNumber) {
      case 0:
        return negativePrefix + "nilordle";
      case 1:
        return negativePrefix + "unordle";
      case 2:
        return negativePrefix + "duordle";
      case 3:
        return negativePrefix + "treordle";
      case 4:
        return negativePrefix + "quordle";
      case 5:
        return negativePrefix + "pentaordle";
      case 6:
        return negativePrefix + "hexaordle";
      case 7:
        return negativePrefix + "septaordle";
      case 8:
        return negativePrefix + "octordle";
      case 9:
        return negativePrefix + "nonordle";
      case 10:
        return negativePrefix + "decaordle";
      default:
        return negativePrefix + "polyordle";
    }
  } else if (absoluteNumber >= 10000) {
    return negativePrefix + "polyordle";
  } else {
    const onesPlaceValue = absoluteNumber % 10;
    const tensPlaceValue = Math.floor((absoluteNumber % 100) / 10);
    const hundredsPlaceValue =
      absoluteNumber >= 100 ? Math.floor((absoluteNumber % 1000) / 100) : null;
    const thousandsPlaceValue =
      absoluteNumber >= 1000
        ? Math.floor((absoluteNumber % 10000) / 1000)
        : null;

    const onesPlacePrefix = (() => {
      switch (onesPlaceValue) {
        case 0:
          return "";
        case 1:
          return "uno";
        case 2:
          return "duo";
        case 3:
          return "tre";
        case 4:
          return "quo";
        case 5:
          return "penta";
        case 6:
          return "hexa";
        case 7:
          return "septa";
        case 8:
          return "octo";
        case 9:
          return "nona";
        default:
          return "";
      }
    })();

    const tensPlacePrefix = (() => {
      switch (tensPlaceValue) {
        case 0:
          return "";
        case 1:
          return "deca";
        case 2:
          return "vigin";
        case 3:
          return "trigin";
        case 4:
          return "quadragin";
        case 5:
          return "pentaquagin";
        case 6:
          return "hexagin";
        case 7:
          return "septagin";
        case 8:
          return "octogin";
        case 9:
          return "nonagin";
        default:
          return "";
      }
    })();

    const hundredsPlacePrefix = (() => {
      if (hundredsPlaceValue === null) {
        return "";
      }

      switch (hundredsPlaceValue) {
        case 0:
          return "";
        case 1:
          return "cent";
        case 2:
          return "ducent";
        case 3:
          return "trecent";
        case 4:
          return "quadcent";
        case 5:
          return "pentacent";
        case 6:
          return "hexacent";
        case 7:
          return "septacent";
        case 8:
          return "octocent";
        case 9:
          return "nonacent";
        default:
          return "";
      }
    })();

    const thousandsPlacePrefix = (() => {
      if (thousandsPlaceValue === null) {
        return "";
      }

      switch (thousandsPlaceValue) {
        case 0:
          return "";
        case 1:
          return "kil";
        case 2:
          return "dukil";
        case 3:
          return "trekil";
        case 4:
          return "quokil";
        case 5:
          return "pentakil";
        case 6:
          return "hexkil";
        case 7:
          return "septkil";
        case 8:
          return "octkil";
        case 9:
          return "nonkil";
        default:
          return "";
      }
    })();

    return `${negativePrefix}${onesPlacePrefix}${tensPlacePrefix}${hundredsPlacePrefix}${thousandsPlacePrefix}ordle`;
  }
};
export default latinizeNameForNumber;

// const test = () => {
//   const results = [];
//   const runTestCase = (number, expectedOutput) => {
//     const actualOutput = latinizeNameForNumber(number);
//     results.push({
//       number,
//       expectedOutput,
//       actualOutput,
//       isPass: expectedOutput === actualOutput,
//     });
//   };

//   runTestCase(0, "nilordle");
//   runTestCase(1, "unordle");
//   runTestCase(2, "duordle");
//   runTestCase(3, "treordle");
//   runTestCase(4, "quordle");
//   runTestCase(5, "quirdle");
//   runTestCase(6, "hexaordle");
//   runTestCase(7, "septaordle");
//   runTestCase(8, "octordle");
//   runTestCase(9, "nonordle");
//   runTestCase(10, "deciordle");
//   runTestCase(11, "unodeciordle");
//   runTestCase(12, "duodeciordle");
//   runTestCase(13, "tredeciordle");
//   runTestCase(14, "quodeciordle");
//   runTestCase(15, "quideciordle");
//   runTestCase(16, "hexadeciordle");
//   runTestCase(17, "septadeciordle");
//   runTestCase(18, "octodeciordle");
//   runTestCase(19, "nonadeciordle");
//   runTestCase(20, "viginordle");
//   runTestCase(21, "unoviginordle");
//   runTestCase(22, "duoviginordle");
//   runTestCase(30, "triginordle");
//   runTestCase(35, "quitriginordle");
//   runTestCase(40, "quadraginordle");
//   runTestCase(50, "quinquaginordle");
//   runTestCase(60, "hexaginordle");
//   runTestCase(70, "septaginordle");
//   runTestCase(80, "octoginordle");
//   runTestCase(90, "nonaginordle");
//   runTestCase(100, "centordle");
//   runTestCase(101, "unocentordle");
//   runTestCase(123, "trevigincentordle");
//   runTestCase(200, "ducentordle");
//   runTestCase(300, "trecentordle");
//   runTestCase(400, "quadcentordle");
//   runTestCase(500, "quincentordle");
//   runTestCase(600, "hexacentordle");
//   runTestCase(700, "septacentordle");
//   runTestCase(800, "octocentordle");
//   runTestCase(900, "nonacentordle");
//   runTestCase(1000, "kilordle");
//   runTestCase(1001, "unokilordle");
//   runTestCase(1012, "duodecikilordle");
//   runTestCase(1234, "quotriginducentkilordle");
//   runTestCase(2000, "dukilordle");
//   runTestCase(3000, "trekilordle");
//   runTestCase(4000, "quokilordle");
//   runTestCase(5000, "quikilordle");
//   runTestCase(6000, "hexkilordle");
//   runTestCase(7000, "septkilordle");
//   runTestCase(8000, "octkilordle");
//   runTestCase(9000, "nonkilordle");

//   console.table(results);
//   console.log(
//     `${filter(results, "isPass").length} pass, ${
//       reject(results, "isPass").length
//     } fail`
//   );
// };
// test();
