import React, { useState } from "react";

import useTitle from "../utils/useTitle";

const Home = () => {
  const [number, setNumber] = useState("");

  const handleChangeNumber = (event) => {
    setNumber(event.target.value);
  };

  const handleGo = () => {
    if (number === "") {
      return;
    }

    window.location.href = "/" + number;
  };

  const handleNumberKeydown = (event) => {
    if (event.key === "Enter") {
      handleGo();
    }
  };

  useTitle("polyordle");

  return (
    <div className="w-screen h-screen flex flex-col items-center justify-center p-1 relative">
      <h1
        className="text-brand-green text-4xl sm:text-6xl flex justify-center items-center"
        style={{ fontFamily: "'Capriola', sans-serif" }}
      >
        polyordle
      </h1>

      <h2 className="mt-4 sm:mt-8 text-gray-600 text-xl sm:text-3xl text-center">
        When duordle, quordle, and octordle just aren't enough
      </h2>

      <div className="mt-16 flex flex-col items-center justify-start">
        <span className="text-gray-700 mr-2 text-xl sm:text-2xl">
          Enter any number of boards:
        </span>
        <div className="mt-1 flex justify-center items-center">
          <input
            type="number"
            className="border border-gray-300 rounded text-xl sm:text-2xl px-2 py-1 w-32"
            placeholder="e.g., 7"
            value={number}
            onChange={handleChangeNumber}
            onKeyDown={handleNumberKeydown}
          />
          <button
            onClick={handleGo}
            className="ml-2 rounded bg-green-500 text-white px-4 py-2 font-bold text-lg"
          >
            Play
          </button>
        </div>
      </div>

      <span className="absolute left-0 right-0 bottom-1 text-center text-gray-700 mb-4">
        Made by{" "}
        <a href="https://danielde.dev" className="text-blue-500">
          Daniel
        </a>{" "}
        and Ellie
      </span>
    </div>
  );
};
export default Home;
