import React, { useState } from "react";
import { map, chunk, filter, includes, every, times, find } from "lodash";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";

const ResultModal = ({
  gameSolutionWords,
  previousGuesses,
  latinizedName,
  dayNumber,
  numBoards,
  onClose,
  isOpen,
}) => {
  const gameResults = map(gameSolutionWords, (solutionWord) =>
    includes(previousGuesses, solutionWord) ? "win" : "lose"
  );

  const numWins = filter(gameResults, (result) => result === "win").length;
  const didWinAll = every(gameResults, (result) => result === "win");

  const resultEmojis = map(gameResults, (result) =>
    result === "win" ? "✅" : "❌"
  );

  const numGridColumnsForResultEmojis =
    find(
      map(times(10), (index) => index + 1),
      (number) => number * number >= gameResults.length
    ) || 10;

  const [hasCopied, setHasCopied] = useState(false);
  const handleCopy = () => {
    const copyText = `
${latinizedName} #${dayNumber}, ${numWins}/${numBoards} boards
polyordle.com/${numBoards}
${map(
  chunk(
    resultEmojis,
    Math.ceil(
      gameResults.length /
        Math.ceil(gameResults.length / numGridColumnsForResultEmojis)
    )
  ),
  (emojis) => emojis.join("")
).join("\n")}`.trim();
    navigator.clipboard.writeText(copyText);

    setHasCopied(true);
    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  };

  return (
    <div
      className={classNames(
        "fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center",
        isOpen ? "bg-white/50" : "bg-transparent pointer-events-none"
      )}
      onClick={onClose}
    >
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="bg-white border border-gray-200 shadow-lg rounded p-4 relative m-2 w-full"
            onClick={(event) => event.stopPropagation()}
            style={{ maxWidth: 400 }}
            initial={{ y: "100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "100%", opacity: 0 }}
            transition={{ opacity: { duration: 0.1 }, y: { duration: 0.2 } }}
          >
            <button
              className="absolute top-2 right-4 rotate-45 font-bold text-4xl"
              onClick={onClose}
            >
              +
            </button>
            <h2
              className={classNames("text-center text-4xl font-bold my-4", {
                "text-green-500": didWinAll,
                "text-red-500": !didWinAll,
              })}
            >
              {didWinAll ? "You won!" : "You lose!"}
            </h2>

            <div
              className="grid justify-center overflow-y-scroll max-h-96 py-1"
              style={{
                gridTemplateColumns: `repeat(${numGridColumnsForResultEmojis}, 32px)`,
              }}
            >
              {map(resultEmojis, (resultEmoji, index) => (
                <span key={index} className="text-2xl">
                  {resultEmoji}
                </span>
              ))}
            </div>

            <div className="flex justify-center items-center">
              <button
                onClick={handleCopy}
                className="mt-4 rounded px-4 py-2 bg-green-500 text-white font-bold text-2xl w-56"
              >
                {hasCopied ? "Copied!" : "Copy results"}
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default ResultModal;
