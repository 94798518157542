import { times } from "lodash";

const seededRandom = (seed) => {
  const sin = Math.sin(seed) * 10000;
  return sin - Math.floor(sin);
};

const getSeededRandomSampleFromArray = (array, numItems, seed) => {
  const startingIndex = Math.floor(
    array.length * seededRandom(seed * seededRandom(numItems))
  );
  const jumpAmount = Math.floor(numItems * seededRandom(numItems)) + 1;

  return times(
    numItems,
    (index) => array[(startingIndex + jumpAmount * index) % array.length]
  );
};
export default getSeededRandomSampleFromArray;
