import React from "react";
import classNames from "classnames";
import { times } from "lodash";
import { motion, AnimatePresence } from "framer-motion";

import gradeWord from "../utils/grade-word";

import { LETTER_BOX_SIDE_LENGTH } from "../size-constants";

const WordleCell = React.memo(({ letter, state }) => {
  return (
    <motion.div
      className={classNames(
        "border border-2 border-gray-500 flex justify-center items-center font-bold text-4xl",
        {
          "text-white dis-bg-green-500": state === "green",
          "text-white dis-bg-yellow-500": state === "yellow",
          "text-white dis-bg-gray-500": state === "gray" || state === "blocked",
        }
      )}
      animate={{
        backgroundColor:
          state === "green"
            ? "#22C55E"
            : state === "yellow"
            ? "#EAB308"
            : state === "gray" || state === "blocked"
            ? "#6B7280"
            : "#FFFFFF",
        color: ["green", "yellow", "gray", "blocked"].includes(state)
          ? "#FFFFFF"
          : "#000000",
      }}
      transition={{ duration: 0.05 }}
      style={{
        width: LETTER_BOX_SIDE_LENGTH,
        height: LETTER_BOX_SIDE_LENGTH,
      }}
    >
      <AnimatePresence>
        {letter !== null && (
          <motion.div
            initial={{ y: "-50%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "-50%", opacity: 0 }}
            transition={{ y: { duration: 0.1 }, opacity: { duration: 0.05 } }}
          >
            {letter}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
});

const WordleRow = ({ solutionWord, word, state }) => {
  const gradeArray = state === "graded" ? gradeWord(solutionWord, word) : null;

  return (
    <div className="flex justify-center items-center gap-x-1">
      {times(5, (letterIndex) => (
        <WordleCell
          key={letterIndex}
          letter={word[letterIndex] || null}
          state={
            state === "blocked"
              ? "blocked"
              : gradeArray !== null
              ? gradeArray[letterIndex]
              : ""
          }
        />
      ))}
    </div>
  );
};
export default React.memo(WordleRow);
