import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import {
  includes,
  filter,
  every,
  flatMap,
  uniq,
  chunk,
  map,
  concat,
} from "lodash";
import { getUnixTime } from "date-fns";
import { useParams } from "react-router-dom";
import { FixedSizeList } from "react-window";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";

import WordleBoard from "./WordleBoard";
import Keyboard from "./Keyboard";
import ResultModal from "./ResultModal";
import { extraWords, solutionWords } from "../words";
import getSeededRandomSampleFromArray from "../utils/seeded-random-sample";
import latinizeNameForNumber from "../utils/number-latinizer";
import { LETTER_BOX_SIDE_LENGTH, ROW_GAP } from "../size-constants";
import useTitle from "../utils/useTitle";

const allowedWords = concat(extraWords, solutionWords);
const allowedLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

const STARTING_DAY_NUMBER = 19043;

const BOARD_WIDTH = LETTER_BOX_SIDE_LENGTH * 6 + ROW_GAP * 4;
const calculateNumBoardsPerRowForCurrentBrowserWidth = () => {
  return Math.max(Math.floor(window.innerWidth / BOARD_WIDTH), 1);
};

const HEADER_BAR_HEIGHT = 68;

const localStorageKey = "com.polyordle.polyordleData";

const getStoredGuessesForDayNumber = (dayNumber, numBoards) => {
  try {
    const storedData = JSON.parse(
      localStorage.getItem(localStorageKey) || "{}"
    );
    if (storedData.dayNumber === dayNumber) {
      return storedData.numBoardsGuessesMap[numBoards] || [];
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const Game = () => {
  const params = useParams();
  const numBoards = parseInt(params.numBoards, 10);
  const isNegative = numBoards < 0;

  useEffect(() => {
    if (isNaN(numBoards)) {
      window.location.href = "/";
    }
  }, [numBoards]);

  const latinizedName = latinizeNameForNumber(numBoards);

  useTitle(latinizedName);

  const dayNumber =
    Math.floor(getUnixTime(new Date()) / (60 * 60 * 24)) -
    STARTING_DAY_NUMBER +
    1;

  const numRows = Math.abs(numBoards) + 5;
  const gameSolutionWords = map(
    getSeededRandomSampleFromArray(
      solutionWords,
      Math.abs(numBoards),
      dayNumber
    ),
    (word) => word.toUpperCase()
  );

  useEffect(() => {
    document.querySelector("body").classList.add("overflow-y-hidden");
  }, []);

  const [keyboardHeight, setKeyboardHeight] = useState(0);
  const keyboardContainerRef = useRef();
  const updateKeyboardHeight = useCallback(() => {
    setKeyboardHeight(
      keyboardContainerRef.current?.getBoundingClientRect().height || 0
    );
  }, []);
  useEffect(() => {
    updateKeyboardHeight();
  }, [updateKeyboardHeight]);

  const [isShowingResultModal, setIsShowingResultModal] = useState(false);

  const [currentGuess, setCurrentGuess] = useState("");

  const [toastMessage, setToastMessage] = useState(null);
  const previousTimeoutIDRef = useRef(null);
  const showToastMessage = (message) => {
    if (previousTimeoutIDRef.current) {
      clearTimeout(previousTimeoutIDRef.current);
    }

    setToastMessage(message);
    previousTimeoutIDRef.current = setTimeout(
      () => setToastMessage(null),
      3000
    );
  };

  const [previousGuesses, setPreviousGuesses] = useState(
    getStoredGuessesForDayNumber(dayNumber, numBoards)
  );
  const updatePreviousGuesses = (newGuesses) => {
    setPreviousGuesses(newGuesses);

    try {
      const storedData = JSON.parse(
        localStorage.getItem(localStorageKey) || "{}"
      );
      let newData = storedData;
      if (storedData.dayNumber !== dayNumber) {
        newData = {
          dayNumber,
          numBoardsGuessesMap: {
            [numBoards]: newGuesses,
          },
        };
      } else {
        newData.numBoardsGuessesMap[numBoards] = newGuesses;
      }

      localStorage.setItem(localStorageKey, JSON.stringify(newData));
    } catch (error) {}
  };

  const numCompletedBoards = useMemo(() => {
    return filter(gameSolutionWords, (solutionWord) =>
      includes(previousGuesses, solutionWord)
    ).length;
  }, [previousGuesses, gameSolutionWords]);
  const guessedLetters = useMemo(
    () =>
      uniq(
        flatMap(previousGuesses, (previousGuess) => previousGuess.split(""))
      ),
    [previousGuesses]
  );

  const [numBoardsPerRow, setNumBoardsPerRow] = useState(
    calculateNumBoardsPerRowForCurrentBrowserWidth()
  );
  const numBoardRows = Math.ceil(Math.abs(numBoards) / numBoardsPerRow);

  const handleKey = (key) => {
    if (key === "Backspace") {
      if (currentGuess.length > 0) {
        setCurrentGuess(currentGuess.slice(0, currentGuess.length - 1));
      }
    } else if (includes(allowedLetters, key.toUpperCase())) {
      if (currentGuess.length < 5) {
        setCurrentGuess(currentGuess + key.toUpperCase());
      }
    } else if (key === "Enter") {
      if (currentGuess.length === 5 && previousGuesses.length < numRows) {
        if (
          includes(allowedWords, currentGuess.toLowerCase()) &&
          !includes(previousGuesses, currentGuess)
        ) {
          updatePreviousGuesses([...previousGuesses, currentGuess]);
          setCurrentGuess("");
        } else {
          if (!includes(allowedWords, currentGuess.toLowerCase())) {
            showToastMessage("Not in word list");
            setCurrentGuess("");
          } else {
            showToastMessage("Already guessed");
            setCurrentGuess("");
          }
        }
      }
    }
  };
  const handleKeyRef = useRef(handleKey);
  handleKeyRef.current = handleKey;

  const isGameOver =
    numBoards !== 0 &&
    (numRows === previousGuesses.length ||
      every(gameSolutionWords, (solutionWord) =>
        includes(previousGuesses, solutionWord)
      ));
  const hasAutoShownResultPopupRef = useRef(false);
  useEffect(() => {
    if (isGameOver && !hasAutoShownResultPopupRef.current) {
      hasAutoShownResultPopupRef.current = true;
      setIsShowingResultModal(true);
    }
  }, [isGameOver]);

  useEffect(() => {
    const keydownListener = (event) => {
      if (event.altKey || event.ctrlKey || event.metaKey) {
        return;
      }

      handleKeyRef.current(event.key);
    };

    document.addEventListener("keydown", keydownListener);

    return () => document.removeEventListener("keydown", keydownListener);
  }, [numRows]);

  useEffect(() => {
    const resizeListener = (event) => {
      setNumBoardsPerRow(calculateNumBoardsPerRowForCurrentBrowserWidth());
      updateKeyboardHeight();
    };

    window.addEventListener("resize", resizeListener, { passive: true });

    return () => window.removeEventListener("resize", resizeListener);
  }, [updateKeyboardHeight]);

  const itemData = useMemo(() => {
    return {
      gameSolutionWordsByRow: chunk(gameSolutionWords, numBoardsPerRow),
      numBoardsPerRow,
      currentGuess,
      previousGuesses,
      numRows,
      isNegative,
    };
  }, [
    gameSolutionWords,
    numBoardsPerRow,
    currentGuess,
    previousGuesses,
    numRows,
    isNegative,
  ]);

  return (
    <div className="relative h-screen">
      <div
        className="border-b border-gray-200 flex justify-start sm:justify-center items-center relative mx-4"
        style={{ height: HEADER_BAR_HEIGHT }}
      >
        <div className="flex justify-center items-center relative w-full max-w-4xl">
          {isGameOver && (
            <div
              className="absolute top-0 right-0 flex justify-center items-center"
              style={{ height: HEADER_BAR_HEIGHT }}
            >
              <button onClick={() => setIsShowingResultModal(true)}>📈</button>
            </div>
          )}

          <div className="flex flex-col items-start sm:items-center justify-start w-full">
            <a
              href="/"
              className="text-ellipsis overflow-hidden"
              style={{ maxWidth: "80%" }}
            >
              <h1
                className="text-3xl sm:text-4xl text-brand-green"
                style={{ fontFamily: "'Capriola', sans-serif" }}
              >
                {latinizedName}
              </h1>
            </a>

            <span className="text-gray-600">
              {numBoards} board{numBoards === 1 ? "" : "s"},{" "}
              {numCompletedBoards} complete
            </span>
          </div>
        </div>
      </div>

      <FixedSizeList
        height={window.innerHeight - HEADER_BAR_HEIGHT - keyboardHeight}
        itemCount={numBoardRows}
        itemSize={(numRows + 1) * LETTER_BOX_SIDE_LENGTH + numRows * ROW_GAP}
        width="100%"
        itemData={itemData}
      >
        {Row}
      </FixedSizeList>

      <Keyboard
        onPress={handleKey}
        guessedLetters={guessedLetters}
        containerRef={keyboardContainerRef}
        isUpsideDown={isNegative}
      />

      <ResultModal
        gameSolutionWords={gameSolutionWords}
        previousGuesses={previousGuesses}
        latinizedName={latinizedName}
        dayNumber={dayNumber}
        numBoards={numBoards}
        onClose={() => setIsShowingResultModal(false)}
        isOpen={isShowingResultModal}
      />

      <AnimatePresence>
        {toastMessage !== null && (
          <motion.div
            className="fixed top-16 left-0 right-0 flex justify-center items-center"
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "-100%", opacity: 0 }}
            transition={{ opacity: { duration: 0.1 }, y: { duration: 0.2 } }}
          >
            <div className="bg-white shadow-lg px-8 py-4 rounded text-2xl bg-red-500 text-white font-bold">
              {toastMessage}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {numBoards === 0 && (
        <div className="fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center">
          What'd you expect?
        </div>
      )}
    </div>
  );
};
export default Game;

const Row = ({ index, style, data }) => {
  const {
    gameSolutionWordsByRow,
    numBoardsPerRow,
    currentGuess,
    previousGuesses,
    numRows,
    isNegative,
  } = data;

  return (
    <div
      style={style}
      className={classNames("flex justify-center gap-x-4 mt-2", {
        "rotate-180": isNegative,
      })}
    >
      {map(gameSolutionWordsByRow[index], (solutionWord, solutionIndex) => (
        <WordleBoard
          key={solutionWord}
          boardNumber={index * numBoardsPerRow + solutionIndex + 1}
          solutionWord={solutionWord}
          numRows={numRows}
          currentGuess={currentGuess}
          previousGuesses={previousGuesses}
        />
      ))}
    </div>
  );
};
